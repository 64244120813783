import React, { useState } from 'react';
import './Header.css';

import logo from './images/logo.jpeg'



export default function Header() {

    const [menuBar, setMenuBar] = useState(false)

    const [icon, setIcon] = useState("fas fa-bars")

    function MenuBars() {

        setMenuBar(!menuBar)

        if (menuBar === true) {

            setIcon("fas fa-bars")

        }
        else {
            setIcon("fas fa-times")

        }

    }

    function MenuBarsFalse() {

        setMenuBar(false)

        setIcon("fas fa-bars")

    }

    const style = {

    }

    if (menuBar === true) {

        style.visibility = "visible"
        style.opacity = "1"

    }

    return (
        <div className="header" >

            <div className="menuBar" >
                <i onClick={MenuBars} className={icon}></i>

            </div>

            <div className="matrixxDesigns">

               
                    <img src={logo} alt="" onClick={MenuBarsFalse} />
        

            </div>


            <div style={style} className="menubar-items">

                <div className="menubar-hold">


                    <ul>
                        <li>Home</li>
                        <li>Products</li>
                        <li>About us</li>
                        <li>Contact Us</li>


                    </ul>


                    <div className="social">

                        <a href="https://www.instagram.com/matrixxdesigns/" className="fab fa-instagram" />

                        <a href="https://www.facebook.com/Matrixx-Designs-106216728193251" className="fab fa-facebook-f" />

                        <a href="https://youtube.com/shorts/YBt6uyCvw84?feature=share" className="fab fa-youtube" />

                        <a href="https://www.linkedin.com/in/matrixx-designs-27a095216" className="fab fa-linkedin" />

                    </div>

                </div>
            </div>

        </div>
    )
}
