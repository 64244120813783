import React from 'react';

import './Home.css';

import data from "./data"

import ProductList from './ProductList'

import Footer from './Footer';


export default function Home() {

    return (

        <div className="home">

            <div className="banner" >

                <h1>site in progress...</h1>

                <div className='loading_bar_bg'>

                    <div className='loading_bar'>

                    </div>

                </div>

            </div>

            <div className="make-row">

                {data.map(prod => (

                    <div>

                        <ProductList prod={prod} key={prod.id} />

                    </div>

                ))}

            </div>


            <Footer />

        </div>

    );
}
