
const data = [
    {
        id: "1",
        img: "img1",
        name: "Business Card",

    },
    {
        id: "2",
        img: "img2",
        name: "Business Card",

    },
    {
        id: "3",
        img: "img3",
        name: "Business Card",

    },
    {
        id: "4",
        img: "img4",
        name: "Business Card",

    },
    {
        id: "5",
        img: "img5",
        name: "Business Card",

    },
    {
        id: "6",
        img: "img6",
        name: "Business Card",

    },


]


export default data;
