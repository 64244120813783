import React from "react";

import './ProductList.css'


const ProductList = ({ prod }) => {

  return (
    <div className='product_list'>

      <img src={require(`./images/${prod.img}.jpg`)} alt="" />

    </div>

  );
};

export default ProductList;
