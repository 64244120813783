import React, { useState, useEffect } from 'react'
import './Footer.css'

function Footer() {


    return (


        <div className="footer">


            <div className="contact-address">

                <div>

                    <i className="fab fa-whatsapp"></i>
                    <h1>WhatsApp</h1>
                    <h2><a href="https://wa.me/+9544044144">+91 9544044144</a></h2>
                </div>

                <div>
                    <i className="fas fa-phone"></i>
                    <h1>Phone Number</h1>
                    <h2><a href="tel:7907418706">0495 2801730</a></h2>
                </div>

                <div>
                    <i className="far fa-envelope"></i>
                    <h1>Email Address</h1>
                    <h2><a href="mailto:safeenakgm@yahoo.com">safeenakgm@yahoo.com</a></h2>
                </div>

            </div>


            <div className="footer-text">

                <div className="social-media">

                    <a href="https://www.instagram.com/safeena_electricals/?hl=en" className="fab fa-instagram" />

                    <a href="https://m.facebook.com/people/Safeena-Electricals-Kunnamangalam/100003931876488" className="fab fa-facebook-f" />

                    <a href="https://www.instagram.com/safeena_electricals/?hl=en" className="fab fa-youtube" />


                </div >

            </div>

            <div className="footer-last">
                <h1>© 2022 rights reserverd</h1>
            </div>

        </div >
    )
}

export default Footer;
